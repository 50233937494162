import { SERVER_URL } from "./api";
import localForage from "localforage";
import { capitalize, padStart } from "lodash";

export const titlecase = (str) => {
    return str.split(" ").map(capitalize).join(" ");
};

export const trackName = (concertSong) => {
    const useAscii = retrieveSetting("useAscii");
    const trackNumber = padStart(concertSong.trackNumber, 2, "0");
    let output = "";
    if (useAscii) {
        output = `${trackNumber} - ${titlecase(
            concertSong.song.fullAsciiName,
        )}`;
    } else {
        output = `${trackNumber} - ${concertSong.song.fullName}`;
    }
    if (concertSong.note) {
        return `${output} (${concertSong.note})`;
    }
    return output;
};

export const fetchUrl = async (track) => {
    try {
        const potentialBlob = await localForage.getItem(`tracks/${track.uuid}`);
        if (potentialBlob) {
            const blobUrl = URL.createObjectURL(potentialBlob);
            return blobUrl;
        } else {
            return await fetchAudioUrl(track);
        }
    } catch {
        return null;
    }
};

export const fetchAudioUrl = async (track) => {
    const response = await fetch(`${SERVER_URL}/track/${track.uuid}`);
    const data = await response.json();
    return data.url;
};

export const cacheAudio = async (trackUuid, url) => {
    try {
        const response = await fetch(url);
        const blob = response.blob();
        localForage.setItem(`tracks/${trackUuid}`, blob);
    } catch {
        // pass
    }
};

export const retrieveSetting = (key) => {
    return JSON.parse(localStorage.getItem(key)) || false;
};

export const storeSetting = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const retrieveOfflineConcerts = async () => {
    const concerts = await localForage.getItem("concerts");
    if (!concerts) {
        return {};
    }
    return concerts;
};

export const secondsToDisplayString = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const paddedSeconds = padStart(String(Math.floor(seconds) % 60), 2, "0");
    return `${minutes}:${paddedSeconds}`;
};
