import * as Grommet from "grommet";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import TextInput from "./TextInput";
import SongAutocomplete from "./SongAutocomplete";

const SongDetails = ({ state, dispatch }) => {
    const history = useHistory();
    const params = useParams();

    const index = React.useMemo(() => {
        return parseInt(params.index);
    }, [params]);
    const song = React.useMemo(() => {
        return state.songs[parseInt(index)];
    }, [index, state.songs]);

    const next = React.useCallback(async () => {
        if (index + 1 < state.songs.length) {
            history.push(`/upload/songs/${index + 1}`);
        } else {
            history.push("/upload/summary");
        }
    }, [history, index, state]);

    if (!song) {
        return null;
    }

    return (
        <Grommet.Box pad="medium" flex="grow">
            <Grommet.Heading level={2}>Song Details</Grommet.Heading>
            <Grommet.Text margin={{ horizontal: "small" }}>
                {song.file.name}
            </Grommet.Text>
            <TextInput
                name={`songs[${index}].trackNumber`}
                label="Track Number"
                state={state}
                dispatch={dispatch}
                min={1}
                max={state.songs.length}
                type="number"
            />
            <TextInput
                name={`songs[${index}].note`}
                label="Note (optional)"
                state={state}
                dispatch={dispatch}
            />
            <SongAutocomplete
                key={index}
                state={state}
                dispatch={dispatch}
                index={index}
            />
            <Grommet.Box direction="row" gap="small" margin={{ top: "medium" }}>
                <Grommet.Button label="Back" onClick={history.goBack} />
                <Grommet.Button
                    primary={true}
                    label="Next"
                    onClick={next}
                    disabled={!song.song || !song.song.uuid}
                />
            </Grommet.Box>
        </Grommet.Box>
    );
};

export default SongDetails;
