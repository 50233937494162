import React from "react";
import * as Grommet from "grommet";
import { get } from "lodash";

const CheckBox = ({ name, label, placeholder, state, dispatch }) => {
    const onChange = React.useCallback(
        (event) => {
            const name = event.target.name;
            const value = event.target.checked;
            dispatch({ type: "on-change", payload: { name, value } });
        },
        [dispatch],
    );

    return (
        <Grommet.CheckBox
            name={name}
            label={label}
            placeholder={placeholder}
            checked={get(state, name)}
            onChange={onChange}
        />
    );
};

export default CheckBox;
