import * as Grommet from "grommet";
import * as qs from "query-string";
import React from "react";
import TextInput from "./TextInput";
import { useMutation } from "graphql-hooks";
import { useHistory } from "react-router-dom";
import { USER } from "./fragments";
import { AppContext } from "./context";

const reducer = (state, action) => {
    switch (action.type) {
        case "on-change": {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value,
            };
        }
        default: {
            throw new Error("Unknown action for Login reducer.");
        }
    }
};

const INITIAL_STATE = {
    email: "",
    password: "",
};

const LOGIN_MUTATION = `
mutation Login($input: LoginInput!) {
    login(input: $input) {
        accessToken {
            token
            user {
                ${USER}
            }
        }
    }
}
`;

const Login = () => {
    const history = useHistory();
    const { appDispatch } = React.useContext(AppContext);
    const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
    const [login] = useMutation(LOGIN_MUTATION);

    const onClick = React.useCallback(async () => {
        const result = await login({
            variables: {
                input: {
                    email: state.email,
                    password: state.password,
                },
            },
        });
        if (result.error) {
            alert("Unable to login! Please try again later.");
            return;
        }
        const { user, token } = result.data.login.accessToken;
        window.localStorage.setItem("TOKEN", token);
        appDispatch({ type: "set-me", payload: user });
        const queryParams = qs.parse(history.location.search);
        if (queryParams && queryParams.redirect) {
            history.push(decodeURIComponent(queryParams.redirect));
        } else {
            history.push("/library");
        }
    }, [appDispatch, history, login, state.email, state.password]);

    return (
        <Grommet.Box pad="medium">
            <TextInput
                name="email"
                label="Email"
                state={state}
                dispatch={dispatch}
            />
            <TextInput
                name="password"
                label="Password"
                type="password"
                state={state}
                dispatch={dispatch}
            />
            <Grommet.Box direction="row" gap="small">
                <Grommet.Button
                    primary={true}
                    label="Login"
                    onClick={onClick}
                    disabled={!(state.email && state.password)}
                />
            </Grommet.Box>
        </Grommet.Box>
    );
};

export default Login;
