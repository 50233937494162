import * as Grommet from "grommet";
import * as Icons from "grommet-icons";
import CheckBox from "./CheckBox";
import React from "react";
import { useHistory } from "react-router-dom";
import ArtistAutocomplete from "./ArtistAutocomplete";
import TextInput from "./TextInput";
import MaskedInput from "./MaskedInput";
import { padStart } from "lodash";

const ArtistForm = ({ state, dispatch, index }) => {
    const removeArtist = React.useCallback(() => {
        dispatch({ type: "remove-artist", payload: index });
    }, [dispatch, index]);

    return (
        <Grommet.Box pad="medium" direction="row" gap="medium">
            <ArtistAutocomplete
                state={state}
                dispatch={dispatch}
                index={index}
            />
            <CheckBox
                name={`artists[${index}].main`}
                label="Main?"
                state={state}
                dispatch={dispatch}
            />
            <Grommet.Button
                icon={<Icons.FormClose />}
                alignSelf="center"
                label="Remove"
                size="small"
                onClick={removeArtist}
            />
        </Grommet.Box>
    );
};

const ConcertDetails = ({ state, dispatch }) => {
    const history = useHistory();

    const addArtist = React.useCallback(() => {
        dispatch({ type: "add-artist" });
    }, [dispatch]);

    return (
        <Grommet.Box pad="medium" flex="grow">
            <Grommet.Heading level={2}>Concert Details</Grommet.Heading>
            <MaskedInput
                name="date"
                label="Date"
                mask={[
                    {
                        length: 4,
                        options: Array.from({ length: 100 }, (v, k) => {
                            return new Date().getFullYear() - k;
                        }),
                        regexp: /^[1-2]$|^19$|^20$|^19[0-9]$|^20[0-9]$|^19[0-9][0-9]$|^20[0-9][0-9]$/,
                        placeholder: "YYYY",
                    },
                    { fixed: "-" },
                    {
                        length: [1, 2],
                        options: Array.from({ length: 12 }, (_, k) => {
                            return padStart(String(k + 1), 2, "0");
                        }),
                        regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                        placeholder: "MM",
                    },
                    { fixed: "-" },
                    {
                        length: [1, 2],
                        options: Array.from(
                            {
                                length: 31,
                            },
                            (_, k) => {
                                return padStart(String(k + 1), 2, "0");
                            },
                        ),
                        regexp: /^[1-2][0-9]$|^3[0-1]$|^0?[1-9]$|^0$/,
                        placeholder: "DD",
                    },
                ]}
                required={true}
                state={state}
                dispatch={dispatch}
            />
            <TextInput
                name="venue"
                label="Venue"
                state={state}
                dispatch={dispatch}
            />
            <Grommet.Box direction="row">
                <Grommet.Button label="Add Artist" onClick={addArtist} />
            </Grommet.Box>
            {state.artists.map((_, index) => {
                return (
                    <ArtistForm
                        key={index}
                        state={state}
                        dispatch={dispatch}
                        index={index}
                    />
                );
            })}
            <Grommet.Box direction="row" gap="small" margin={{ top: "medium" }}>
                <Grommet.Button label="Back" onClick={history.goBack} />
                <Grommet.Button
                    primary={true}
                    label="Next"
                    onClick={() => {
                        history.push("/upload/songs/0");
                    }}
                    disabled={
                        state.artists.length <= 0 ||
                        state.artists.filter((artist) => {
                            return artist.main;
                        }).length <= 0
                    }
                />
            </Grommet.Box>
        </Grommet.Box>
    );
};

export default ConcertDetails;
