import React from "react";
import * as Grommet from "grommet";
import { AppContext } from "./context";
import localForage from "localforage";
import {
    cacheAudio,
    fetchAudioUrl,
    trackName,
    retrieveOfflineConcerts,
} from "./helpers";
import ConcertHeading from "./ConcertHeading";
import ConcertArtists from "./ConcertArtists";

const Notifications = () => {
    const { appState, appDispatch } = React.useContext(AppContext);
    const [downloadingTrackIndex, setDownloadingTrackIndex] = React.useState(0);

    const toggle = React.useCallback(() => {
        appDispatch({ type: "toggle-notifications" });
    }, [appDispatch]);

    const currentConcert = React.useMemo(() => {
        return appState.downloadQueue[0];
    }, [appState.downloadQueue]);

    const currentConcertSongs = React.useMemo(() => {
        if (!currentConcert) {
            return null;
        }
        return currentConcert.songs.edges.map((edge) => {
            return edge.node;
        });
    }, [currentConcert]);

    React.useEffect(() => {
        const cacheConcert = async () => {
            try {
                for (let i = 0; i < currentConcertSongs.length; i++) {
                    setDownloadingTrackIndex(i);
                    const concertSong = currentConcertSongs[i];
                    const url = await fetchAudioUrl(concertSong);
                    await cacheAudio(concertSong.uuid, url);
                }
                const offlineConcerts = await retrieveOfflineConcerts();
                offlineConcerts[currentConcert.uuid] = currentConcert;
                await localForage.setItem("concerts", offlineConcerts);
            } catch {
                alert(
                    "An error occurred trying to save a concert for offline use. Please try again later!",
                );
            } finally {
                toggle();
                appDispatch({ type: "pop-from-download-queue" });
            }
        };
        if (currentConcert) {
            cacheConcert();
        }
    }, [currentConcertSongs, currentConcert, appDispatch, toggle]);

    if (!appState.showNotifications) {
        return null;
    }

    return (
        <Grommet.Layer onEsc={toggle} onClickOutside={toggle}>
            <Grommet.Box overflow="scroll" pad="medium">
                {currentConcert ? (
                    <Grommet.Box>
                        <ConcertHeading concert={currentConcert} />a
                        <ConcertArtists
                            artists={currentConcert.artists.edges.map(
                                (edge) => {
                                    return edge.node;
                                },
                            )}
                        />
                        <Grommet.Text>
                            Now downloading:{" "}
                            {trackName(
                                currentConcertSongs[downloadingTrackIndex],
                            )}
                        </Grommet.Text>
                        <Grommet.Meter
                            values={[
                                {
                                    value:
                                        ((downloadingTrackIndex + 1) /
                                            currentConcertSongs.length) *
                                        100.0,
                                },
                            ]}
                        />
                    </Grommet.Box>
                ) : (
                    <Grommet.Text>Nothing to see here...</Grommet.Text>
                )}
                <Grommet.Box
                    direction="row"
                    margin={{ top: "small" }}
                    gap="small"
                >
                    <Grommet.Button label="Close" onClick={toggle} />
                </Grommet.Box>
            </Grommet.Box>
        </Grommet.Layer>
    );
};

export default Notifications;
