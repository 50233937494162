import * as Grommet from "grommet";
import * as fragments from "./fragments";
import Autocomplete from "./Autocomplete";
import React from "react";
import SongForm from "./SongForm";
import { retrieveSetting } from "./helpers";
import { AppContext } from "./context";

const SONG_QUERY = `
    query Songs($search: String!) {
        songs(search: $search) {
            ${fragments.SONG}
        }
    }
`;

const SongAutocomplete = ({ state, dispatch, index }) => {
    const { appState } = React.useContext(AppContext);
    const [formVisible, setFormVisible] = React.useState(false);
    const useAscii = React.useMemo(() => {
        return retrieveSetting("useAscii");
    }, []);

    const toggleVisibility = React.useCallback(() => {
        setFormVisible((prevVisible) => {
            return !prevVisible;
        });
    }, []);

    const name = `songs[${index}].song`;

    return (
        <Grommet.Box>
            <Autocomplete
                name={name}
                label="Song"
                query={SONG_QUERY}
                queryKey="songs"
                mutation=""
                labelKey={useAscii ? "fullAsciiName" : "fullName"}
                state={state}
                dispatch={dispatch}
            />
            {appState.me && appState.me.role === "admin" && (
                <Grommet.Anchor onClick={toggleVisibility}>
                    Create a New Song
                </Grommet.Anchor>
            )}
            {formVisible && (
                <SongForm
                    close={toggleVisibility}
                    onCreate={(newSong) => {
                        dispatch({
                            type: "on-change",
                            payload: { name, value: newSong },
                        });
                    }}
                />
            )}
        </Grommet.Box>
    );
};

export default SongAutocomplete;
