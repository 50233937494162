import * as Grommet from "grommet";
import ConcertList from "./ConcertList";
import React from "react";
import { orderBy } from "lodash";
import { retrieveOfflineConcerts } from "./helpers";

const Offline = () => {
    const [offlineConcerts, setOfflineConcerts] = React.useState({});

    React.useEffect(() => {
        const loadOfflineConcerts = async () => {
            setOfflineConcerts(await retrieveOfflineConcerts());
        };
        loadOfflineConcerts();
    }, []);

    const concerts = orderBy(Object.values(offlineConcerts), "fullName", "asc");
    return (
        <Grommet.Box pad="medium" flex="grow">
            <Grommet.Heading level={2}>Offline</Grommet.Heading>
            {concerts.length > 0 ? (
                <ConcertList concerts={concerts} />
            ) : (
                <Grommet.Text>No concerts found.</Grommet.Text>
            )}
        </Grommet.Box>
    );
};

export default Offline;
