import * as Grommet from "grommet";
import * as Icons from "grommet-icons";
import * as fragments from "./fragments";
import ConcertList from "./ConcertList";
import React from "react";
import { useQuery } from "graphql-hooks";
import { get } from "lodash";
import { useHistory } from "react-router-dom";
import { useAuthRedirect } from "./hooks";

const ALL_CONCERTS_QUERY = `
    query AllConcertsQuery($first: Int, $after: String) {
        allConcerts(first: $first, after: $after, sort: DATE_ASC) {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ${fragments.CONCERT}
                }
            }
        }
    }
`;

const PAGE_SIZE = 25;

const updateData = (prevData, data) => {
    return {
        ...data,
        allConcerts: {
            ...data.allConcerts,
            edges: [...prevData.allConcerts.edges, ...data.allConcerts.edges],
        },
    };
};

const Library = () => {
    const history = useHistory();
    const [searchText, setSearchText] = React.useState("");
    const [after, setAfter] = React.useState(null);
    const { loading, error, data, refetch } = useQuery(ALL_CONCERTS_QUERY, {
        variables: {
            first: PAGE_SIZE,
            after,
        },
        updateData,
    });

    useAuthRedirect(error);

    const onChange = React.useCallback((event) => {
        setSearchText(event.target.value);
    }, []);

    const onSubmit = React.useCallback(() => {
        history.push(`/search/${searchText}`);
    }, [history, searchText]);

    const onKeyPress = React.useCallback(
        (event) => {
            if (event.key === "Enter") {
                onSubmit();
            }
        },
        [onSubmit],
    );

    const onMore = React.useCallback(() => {
        if (get(data, "allConcerts.pageInfo.hasNextPage")) {
            setAfter(data.allConcerts.pageInfo.endCursor);
        }
    }, [data]);

    return (
        <Grommet.Box pad="medium" flex="grow">
            <Grommet.Heading level={2}>Library</Grommet.Heading>
            {loading ? <Grommet.Text>Loading...</Grommet.Text> : null}
            {error ? (
                <Grommet.Box gap="small">
                    <Grommet.Text>
                        An error occurred! Please click the button below to
                        reload.
                    </Grommet.Text>
                    <Grommet.Box direction="row">
                        <Grommet.Button
                            label="Reload"
                            primary={true}
                            onClick={refetch}
                        />
                    </Grommet.Box>
                </Grommet.Box>
            ) : null}
            {get(data, "allConcerts.edges", []).length > 0 ? (
                <Grommet.Box gap="medium">
                    <Grommet.TextInput
                        icon={<Icons.Search />}
                        placeholder="Search..."
                        value={searchText}
                        onChange={onChange}
                        onSubmit={onSubmit}
                        onKeyPress={onKeyPress}
                        type="search"
                    />
                    <ConcertList
                        concerts={data.allConcerts.edges.map((edge) => {
                            return edge.node;
                        })}
                        onMore={onMore}
                        step={PAGE_SIZE}
                    />
                </Grommet.Box>
            ) : !(loading || error) ? (
                <Grommet.Text>No concerts found.</Grommet.Text>
            ) : null}
        </Grommet.Box>
    );
};

export default Library;
