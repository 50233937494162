export const BASE_CONCEPT = `
    id
    uuid
    name
    asciiName
`;

export const USER = `
    id
    uuid
    email
    role
`;

export const SONG = `
    id
    uuid
    name
    asciiName
    fullName
    fullAsciiName
    ragamUuid
    thalamUuid
    composerUuid
`;

export const CONCERT_SONG = `
    id
    uuid
    trackNumber
    note
    cloudPath
    concertUuid
    song {
        ${SONG}
    }
`;

export const CONCERT = `
    id
    uuid
    date
    venue
    fullName
    artists {
        edges {
            node {
                artist {
                    ${BASE_CONCEPT}
                }
            }
        }
    }
    songs {
        edges {
            node {
                ${CONCERT_SONG}
            }
        }
    }
`;
