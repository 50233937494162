import React from "react";
import * as Grommet from "grommet";
import * as Icons from "grommet-icons";
import { AppContext } from "./context";
import { useHistory } from "react-router-dom";

const Menu = () => {
    const history = useHistory();
    const { appState, appDispatch } = React.useContext(AppContext);
    const menuItems = React.useMemo(() => {
        const items = [
            {
                label: "Offline",
                icon: <Icons.OfflineStorage />,
                gap: "small",
                onClick: () => {
                    history.push("/offline");
                },
            },
        ];
        if (appState.me) {
            items.unshift({
                label: "Library",
                icon: <Icons.Music />,
                gap: "small",
                onClick: () => {
                    history.push("/library");
                },
            });
            if (
                appState.me.role === "admin" ||
                appState.me.role === "uploader"
            ) {
                items.push({
                    label: "Upload",
                    icon: <Icons.Network />,
                    gap: "small",
                    onClick: () => {
                        history.push("/upload");
                    },
                });
            }
        } else {
            items.unshift({
                label: "Login",
                icon: <Icons.Login />,
                gap: "small",
                onClick: () => {
                    history.push("/login");
                },
            });
        }
        items.push({
            label: "Settings",
            icon: <Icons.SettingsOption />,
            gap: "small",
            onClick: () => {
                history.push("/settings");
            },
        });
        return items;
    }, [appState.me, history]);
    return (
        <Grommet.Header background="brand" pad="small">
            <Grommet.Menu
                icon={<Icons.Menu />}
                dropAlign={{ left: "left", top: "bottom" }}
                items={menuItems}
            />
            <Grommet.Box margin={{ right: "small" }}>
                <Icons.Notification
                    onClick={() => {
                        appDispatch({ type: "toggle-notifications" });
                    }}
                />
            </Grommet.Box>
        </Grommet.Header>
    );
};

export default Menu;
