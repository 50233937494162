import { AppContext } from "./context";
import * as Grommet from "grommet";
import React from "react";
import { trackName } from "./helpers";

const ConcertSongs = ({ songs, playable }) => {
    const { selectTrack } = React.useContext(AppContext);
    const Wrapper = playable ? Grommet.Anchor : Grommet.Text;
    return (
        <Grommet.Box pad={{ vertical: "small" }}>
            {songs.map((concertSong) => {
                return (
                    <Wrapper
                        key={concertSong.song.uuid}
                        onClick={
                            playable
                                ? () => {
                                      selectTrack(concertSong);
                                  }
                                : null
                        }
                    >
                        {trackName(concertSong)}
                        {concertSong.file && ` (from ${concertSong.file.name})`}
                    </Wrapper>
                );
            })}
        </Grommet.Box>
    );
};

export default ConcertSongs;
