import * as Grommet from "grommet";
import React from "react";
import ConcertArtists from "./ConcertArtists";
import ConcertHeading from "./ConcertHeading";
import { useHistory } from "react-router-dom";

const ConcertList = ({ concerts, onMore, step }) => {
    const history = useHistory();
    return (
        <Grommet.Grid
            gap="small"
            rows="small"
            columns={{ count: "fit", size: "small" }}
        >
            <Grommet.InfiniteScroll
                items={concerts}
                onMore={onMore}
                step={step}
            >
                {(concert) => {
                    return (
                        <Grommet.Box
                            key={concert.uuid}
                            pad="small"
                            gap="small"
                            background="brand"
                            overflow={{
                                vertical: "scroll",
                            }}
                            onClick={() => {
                                history.push(`/concert/${concert.uuid}`);
                            }}
                        >
                            <ConcertHeading concert={concert} />
                            <ConcertArtists
                                artists={concert.artists.edges.map((edge) => {
                                    return edge.node;
                                })}
                            />
                        </Grommet.Box>
                    );
                }}
            </Grommet.InfiniteScroll>
        </Grommet.Grid>
    );
};

export default ConcertList;
