import React from "react";
import * as Grommet from "grommet";
import { get } from "lodash";

const TextInput = ({
    name,
    label,
    placeholder,
    required,
    type,
    min,
    max,
    state,
    dispatch,
}) => {
    const onChange = React.useCallback(
        (event) => {
            const name = event.target.name;
            const value = event.target.value;
            dispatch({ type: "on-change", payload: { name, value } });
        },
        [dispatch],
    );

    return (
        <Grommet.FormField name={name} label={label} required={required}>
            <Grommet.TextInput
                name={name}
                placeholder={placeholder}
                value={get(state, name)}
                onChange={onChange}
                type={type}
                min={min}
                max={max}
            />
        </Grommet.FormField>
    );
};

export default TextInput;
