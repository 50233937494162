import * as Grommet from "grommet";
import React from "react";
import { useManualQuery, useMutation } from "graphql-hooks";
import { get } from "lodash";
import { retrieveSetting, titlecase } from "./helpers";
import { useDebouncedCallback } from "use-debounce";
import { AppContext } from "./context";

const Autocomplete = ({
    query,
    queryKey,
    mutation,
    mutationKey,
    name,
    label,
    state,
    dispatch,
    labelKey,
    valueKey,
}) => {
    const { appState } = React.useContext(AppContext);
    const [search, setSearch] = React.useState("");
    const [data, setData] = React.useState([]);
    const [loadOptions] = useManualQuery(query);
    const [create] = useMutation(mutation);
    const useAscii = React.useMemo(() => {
        return retrieveSetting("useAscii");
    }, []);
    const labelFunction = React.useCallback(
        (option) => {
            if (useAscii) {
                return titlecase(option[labelKey || "asciiName"]);
            }
            return option[labelKey || "name"];
        },
        [labelKey, useAscii],
    );
    const value = React.useMemo(() => {
        return get(state, name);
    }, [name, state]);
    const load = React.useCallback(async () => {
        const results = await loadOptions({
            variables: {
                search,
            },
        });
        if (results.error) {
            return;
        }
        setData(get(results.data, queryKey, []));
    }, [loadOptions, queryKey, search]);
    const [debouncedLoad, cancel] = useDebouncedCallback(load, 150);
    React.useEffect(() => {
        cancel();
        debouncedLoad();
    }, [cancel, debouncedLoad, search]);
    const onChange = React.useCallback(
        async ({ option, value }) => {
            if (option.uuid === "new") {
                const confirmation = window.confirm(
                    `Are you sure you want to create a new ${label.toLowerCase()} with name '${search}'?`,
                );
                if (!confirmation) {
                    return;
                }
                const result = await create({
                    variables: { input: { name: search } },
                });
                if (result.error) {
                    alert("An error occurred! Please try again later.");
                    return;
                }
                const newVal = get(result.data, mutationKey, {});
                setData((prevData) => {
                    return [...prevData, newVal];
                });
                dispatch({
                    type: "on-change",
                    payload: { name, value: newVal },
                });
                setSearch("");
            } else {
                dispatch({
                    type: "on-change",
                    payload: { name, value },
                });
            }
        },
        [create, dispatch, label, mutationKey, name, search],
    );
    const options = React.useMemo(() => {
        const output = [...data];
        if (search && mutation && appState.me && appState.me.role === "admin") {
            output.push({ uuid: "new", name: `Create ${search}` });
        }
        return output;
    }, [appState.me, data, mutation, search]);
    return (
        <Grommet.FormField name={name} label={label}>
            <Grommet.Select
                name={name}
                placeholder={`Select ${label}`}
                value={value}
                options={options}
                labelKey={labelFunction}
                valueKey={valueKey || "uuid"}
                onChange={onChange}
                onSearch={setSearch}
            />
        </Grommet.FormField>
    );
};

export default Autocomplete;
