import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { GraphQLClient, ClientContext } from "graphql-hooks";
import * as Grommet from "grommet";
import * as serviceWorker from "./serviceWorker";
import { SERVER_URL } from "./api";
import theme from "./theme";

const client = new GraphQLClient({
    url: `${SERVER_URL}/graphql`,
    fetch: (url, options) => {
        const headers = { ...(options.headers || {}) };
        const token = window.localStorage.getItem("TOKEN");
        headers.Authorization = `Bearer ${token}`;
        return fetch(url, { ...options, headers });
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ClientContext.Provider value={client}>
            <BrowserRouter>
                <Grommet.Grommet
                    theme={theme}
                    style={{ display: "flex", flex: "1 1 auto", minHeight: 0 }}
                >
                    <App />
                </Grommet.Grommet>
            </BrowserRouter>
        </ClientContext.Provider>
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
