import * as Grommet from "grommet";
import * as fragments from "./fragments";
import React from "react";
import { useQuery } from "graphql-hooks";
import { useHistory, useParams } from "react-router-dom";
import ConcertList from "./ConcertList";
import { trackName } from "./helpers";
import ConcertHeading from "./ConcertHeading";
import ConcertArtists from "./ConcertArtists";
import { useAuthRedirect } from "./hooks";

const SEARCH_QUERY = `
    query Search ($search: String!) {
        search(search: $search) {
            concertSongs {
                ${fragments.CONCERT_SONG}
                concert {
                    ${fragments.CONCERT}
                }
            }
            concerts {
                ${fragments.CONCERT}
            }
        }
    }
`;

const Search = () => {
    const history = useHistory();
    const { search } = useParams();
    const { loading, error, data, refetch } = useQuery(SEARCH_QUERY, {
        variables: {
            search,
        },
    });
    useAuthRedirect(error);
    return (
        <Grommet.Box pad="medium" flex="grow">
            <Grommet.Heading level={2}>Search Results</Grommet.Heading>
            {loading && <Grommet.Text>Loading...</Grommet.Text>}
            {error && (
                <Grommet.Box gap="small">
                    <Grommet.Text>
                        An error occurred! Please try again later.
                    </Grommet.Text>
                    <Grommet.Box direction="row">
                        <Grommet.Button
                            label="Reload"
                            primary={true}
                            onClick={refetch}
                        />
                    </Grommet.Box>
                </Grommet.Box>
            )}
            {data && data.search ? (
                <Grommet.Box
                    gap="medium"
                    direction={
                        data.search.concertSongs.length >
                        data.search.concerts.length
                            ? "column"
                            : "column-reverse"
                    }
                >
                    <Grommet.Box>
                        <Grommet.Heading level={3}>Songs</Grommet.Heading>
                        {data.search.concertSongs.map((concertSong) => {
                            return (
                                <Grommet.Box key={concertSong.uuid}>
                                    <Grommet.Anchor
                                        onClick={() => {
                                            history.push(
                                                `/concert/${concertSong.concertUuid}`,
                                            );
                                        }}
                                    >
                                        {trackName(concertSong)}
                                    </Grommet.Anchor>
                                    <Grommet.Box direction="row" gap="small">
                                        <ConcertHeading
                                            concert={concertSong.concert}
                                            size="small"
                                            direction="row"
                                        />
                                        <ConcertArtists
                                            artists={concertSong.concert.artists.edges.map(
                                                (edge) => {
                                                    return edge.node;
                                                },
                                            )}
                                            size="small"
                                            direction="row"
                                        />
                                    </Grommet.Box>
                                </Grommet.Box>
                            );
                        })}
                        {data.search.concertSongs.length <= 0 ? (
                            <Grommet.Text>No songs found.</Grommet.Text>
                        ) : null}
                    </Grommet.Box>
                    <Grommet.Box>
                        <Grommet.Heading level={3}>Concerts</Grommet.Heading>
                        <ConcertList concerts={data.search.concerts} />
                        {data.search.concerts.length <= 0 ? (
                            <Grommet.Text>No concerts found.</Grommet.Text>
                        ) : null}
                    </Grommet.Box>
                </Grommet.Box>
            ) : null}
            {data && data.search ? (
                <Grommet.Box direction="row" margin={{ vertical: "medium" }}>
                    <Grommet.Button label="Back" onClick={history.goBack} />
                </Grommet.Box>
            ) : null}
        </Grommet.Box>
    );
};

export default Search;
