import React from "react";
import { get } from "lodash";
import { useHistory } from "react-router-dom";

export const useAuthRedirect = (error) => {
    const history = useHistory();
    React.useEffect(() => {
        if (
            get(error, "graphQLErrors[0].message") ===
            "Authorization missing or invalid."
        ) {
            history.replace(
                `/login?redirect=${encodeURIComponent(
                    history.location.pathname,
                )}`,
            );
        }
    }, [error, history]);
};
