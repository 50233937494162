import * as Grommet from "grommet";
import * as fragments from "./fragments";
import React from "react";
import Autocomplete from "./Autocomplete";
import { useMutation } from "graphql-hooks";
import TextInput from "./TextInput";

const RAGAM_QUERY = `
    query Ragams($search: String!) {
        ragams(search: $search) {
            ${fragments.BASE_CONCEPT}
        }
    }
`;

const RAGAM_MUTATION = `
    mutation CreateRagam($input: CreateRagamInput!) {
        createRagam(input: $input) {
            ragam {
                ${fragments.BASE_CONCEPT}
            }
        }
    }
`;

const THALAM_QUERY = `
    query Thalams($search: String!) {
        thalams(search: $search) {
            ${fragments.BASE_CONCEPT}
        }
    }
`;

const THALAM_MUTATION = `
    mutation CreateThalam($input: CreateThalamInput!) {
        createThalam(input: $input) {
            thalam {
                ${fragments.BASE_CONCEPT}
            }
        }
    }
`;

const COMPOSER_QUERY = `
    query Composers($search: String!) {
        composers(search: $search) {
            ${fragments.BASE_CONCEPT}
        }
    }
`;

const COMPOSER_MUTATION = `
    mutation CreateComposer($input: CreateComposerInput!) {
        createComposer(input: $input) {
            composer {
                ${fragments.BASE_CONCEPT}
            }
        }
    }
`;

const SONG_MUTATION = `
    mutation CreateSong($input: CreateSongInput!) {
        createSong(input: $input) {
            song {
                ${fragments.SONG}
            }
        }
    }
`;

const reducer = (state, action) => {
    switch (action.type) {
        case "on-change": {
            const { name, value } = action.payload;
            return {
                ...state,
                [name]: value,
            };
        }
        default: {
            throw new Error("Unknown action for SongForm reducer.");
        }
    }
};

const INITIAL_STATE = {
    name: "",
    ragam: {},
    thalam: {},
    composer: {},
};

const SongForm = ({ close, onCreate }) => {
    const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
    const [create] = useMutation(SONG_MUTATION);

    const submit = React.useCallback(async () => {
        if (!state.name) {
            alert("Name is required!");
            return;
        }
        const payload = {
            name: state.name,
        };
        if (state.ragam.uuid) {
            payload.ragamUuid = state.ragam.uuid;
        }
        if (state.thalam.uuid) {
            payload.thalamUuid = state.thalam.uuid;
        }
        if (state.composer.uuid) {
            payload.composerUuid = state.composer.uuid;
        }
        const result = await create({
            variables: {
                input: payload,
            },
        });
        if (result.error) {
            alert("An error occurred! Please try again later.");
            return;
        }
        onCreate(result.data.createSong.song);
        close();
    }, [close, create, onCreate, state]);

    return (
        <Grommet.Layer>
            <Grommet.Box pad="medium">
                <TextInput
                    name="name"
                    label="Name"
                    placeholder="Enter song name"
                    state={state}
                    dispatch={dispatch}
                />
                <Autocomplete
                    name="ragam"
                    label="Ragam"
                    query={RAGAM_QUERY}
                    queryKey="ragams"
                    mutation={RAGAM_MUTATION}
                    mutationKey="createRagam.ragam"
                    state={state}
                    dispatch={dispatch}
                />
                <Autocomplete
                    name="thalam"
                    label="Thalam"
                    query={THALAM_QUERY}
                    queryKey="thalams"
                    mutation={THALAM_MUTATION}
                    mutationKey="createThalam.thalam"
                    state={state}
                    dispatch={dispatch}
                />
                <Autocomplete
                    name="composer"
                    label="Composer"
                    query={COMPOSER_QUERY}
                    queryKey="composers"
                    mutation={COMPOSER_MUTATION}
                    mutationKey="createComposer.composer"
                    state={state}
                    dispatch={dispatch}
                />
                <Grommet.Box
                    direction="row"
                    gap="small"
                    margin={{ vertical: "medium" }}
                >
                    <Grommet.Button
                        type="button"
                        onClick={submit}
                        primary={true}
                        label="Submit"
                    />
                    <Grommet.Button
                        type="button"
                        label="Close"
                        onClick={close}
                    />
                </Grommet.Box>
            </Grommet.Box>
        </Grommet.Layer>
    );
};

export default SongForm;
