import * as Grommet from "grommet";
import * as fragments from "./fragments";
import React from "react";
import { useHistory } from "react-router-dom";
import { cloneDeep } from "lodash";
import { useManualQuery, useMutation } from "graphql-hooks";
import { v4 as uuidv4 } from "uuid";
import ConcertHeading from "./ConcertHeading";
import ConcertArtists from "./ConcertArtists";
import ConcertSongs from "./ConcertSongs";

const UPLOAD_URL_QUERY = `
    query UploadUrl($uuid: String!, $filename: String!) {
        uploadUrl(uuid: $uuid, filename: $filename)
    }
`;

const CONCERT_MUTATION = `
    mutation CreateConcert($input: CreateConcertInput!) {
        createConcert(input: $input) {
            concert {
                ${fragments.CONCERT}
            }
        }
    }
`;

const UploadSummary = ({ state }) => {
    const history = useHistory();
    const [uploading, setUploading] = React.useState(false);
    const [progress, setProgress] = React.useState(null);
    const [create] = useMutation(CONCERT_MUTATION);
    const [getUploadUrl] = useManualQuery(UPLOAD_URL_QUERY);

    const next = React.useCallback(async () => {
        setUploading(true);
        const payload = cloneDeep(state);
        for (const artist of payload.artists) {
            artist.artistUuid = artist.artist.uuid;
            delete artist.artist;
        }
        const uuid = uuidv4();
        const files = [];
        for (const song of payload.songs) {
            song.songUuid = song.song.uuid;
            song.tempCloudPath = `tmp/${uuid}/${song.file.name}`;
            files.push(song.file);
            delete song.song;
            delete song.file;
        }
        for (let i = 0; i < files.length; i++) {
            setProgress((i / files.length) * 100.0);
            const file = files[i];
            const output = await getUploadUrl({
                variables: { uuid, filename: file.name },
            });
            if (output.error) {
                alert("Getting upload URL failed! Please try again later.");
                return;
            }
            const uploadUrl = output.data.uploadUrl;
            try {
                await fetch(uploadUrl, {
                    method: "PUT",
                    headers: {
                        Accept: "*/*",
                        "Content-Type": file.type,
                    },
                    body: file,
                });
            } catch {
                alert(
                    "Upload to cloud storage failed! Please try again later.",
                );
                setProgress(null);
                setUploading(false);
                return;
            }
        }
        setProgress(100.0);
        const result = await create({
            variables: {
                input: payload,
            },
        });
        setProgress(null);
        setUploading(false);
        if (result.error) {
            alert("Upload to library failed! Please try again later.");
        } else {
            history.push("/library");
        }
    }, [create, getUploadUrl, history, state]);

    return (
        <Grommet.Box pad="medium" flex="grow">
            <Grommet.Heading level={2}>Upload Summary</Grommet.Heading>
            <ConcertHeading concert={state} />
            <ConcertArtists artists={state.artists} />
            <ConcertSongs songs={state.songs} />
            <Grommet.Box direction="row" gap="small" margin={{ top: "medium" }}>
                <Grommet.Button
                    label="Back"
                    onClick={history.goBack}
                    disabled={uploading}
                />
                <Grommet.Button
                    primary={true}
                    label={uploading ? "Uploading..." : "Upload"}
                    disabled={uploading}
                    onClick={next}
                />
            </Grommet.Box>
            {uploading && progress !== null ? (
                <Grommet.Layer>
                    <Grommet.Box pad="medium">
                        <Grommet.Heading level={3}>
                            {progress < 100.0
                                ? "Uploading..."
                                : "Processing..."}
                        </Grommet.Heading>
                        <Grommet.Meter
                            values={[
                                {
                                    value: progress,
                                },
                            ]}
                        />
                    </Grommet.Box>
                </Grommet.Layer>
            ) : null}
        </Grommet.Box>
    );
};

export default UploadSummary;
