import * as fragments from "./fragments";
import Autocomplete from "./Autocomplete";
import React from "react";

const ARTIST_QUERY = `
    query Artists($search: String!) {
        artists(search: $search) {
            ${fragments.BASE_CONCEPT}
        }
    }
`;

const ARTIST_MUTATION = `
    mutation CreateArtist($input: CreateArtistInput!) {
        createArtist(input: $input) {
            artist {
                ${fragments.BASE_CONCEPT}
            }
        }
    }
`;

const ArtistAutocomplete = ({ state, dispatch, index }) => {
    return (
        <Autocomplete
            name={`artists[${index}].artist`}
            label="Artist"
            query={ARTIST_QUERY}
            queryKey="artists"
            mutation={ARTIST_MUTATION}
            mutationKey="createArtist.artist"
            state={state}
            dispatch={dispatch}
        />
    );
};

export default ArtistAutocomplete;
