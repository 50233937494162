import * as Grommet from "grommet";
import * as Icons from "grommet-icons";
import * as fragments from "./fragments";
import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useManualQuery } from "graphql-hooks";
import ConcertHeading from "./ConcertHeading";
import ConcertArtists from "./ConcertArtists";
import ConcertSongs from "./ConcertSongs";
import { AppContext } from "./context";
import { retrieveOfflineConcerts } from "./helpers";
import { useAuthRedirect } from "./hooks";

const CONCERT_QUERY = `
    query ConcertQuery($uuid: String!) {
        concert(uuid: $uuid) {
            ${fragments.CONCERT}
        }
    }
`;

const Concert = () => {
    const history = useHistory();
    const { uuid } = useParams();
    const { appState, appDispatch } = React.useContext(AppContext);
    const [queryResults, setQueryResults] = React.useState({
        loading: true,
        error: null,
        data: null,
        offline: false,
    });
    const [downloadDisabled, setDownloadDisabled] = React.useState(true);
    const [query] = useManualQuery(CONCERT_QUERY);

    const load = React.useCallback(async () => {
        const offlineConcerts = await retrieveOfflineConcerts();
        if (offlineConcerts[uuid]) {
            setQueryResults({
                loading: false,
                error: null,
                data: {
                    concert: offlineConcerts[uuid],
                },
                offline: true,
            });
        } else {
            const results = await query({
                variables: {
                    uuid,
                },
            });
            setQueryResults(results);
        }
    }, [query, uuid]);

    React.useEffect(() => {
        load();
    }, [load, query, uuid]);

    useAuthRedirect(queryResults.error);

    React.useEffect(() => {
        const determineDisabled = async () => {
            if (
                queryResults.loading ||
                queryResults.error ||
                !queryResults.data ||
                !queryResults.data.concert
            ) {
                setDownloadDisabled(true);
                return;
            }
            const concerts = await retrieveOfflineConcerts();
            if (queryResults.data.concert.uuid in concerts) {
                setDownloadDisabled(true);
                return;
            }
            const downloadQueueUuids = appState.downloadQueue.map((concert) => {
                return concert.uuid;
            });
            if (downloadQueueUuids.includes(queryResults.data.concert.uuid)) {
                setDownloadDisabled(true);
                return;
            }
            setDownloadDisabled(false);
        };
        determineDisabled();
    }, [appState.downloadQueue, queryResults]);

    // TODO: disable download button if (already downloaded) or (in progress)
    const download = React.useCallback(() => {
        appDispatch({
            type: "add-to-download-queue",
            payload: queryResults.data.concert,
        });
        appDispatch({ type: "toggle-notifications" });
    }, [appDispatch, queryResults]);

    return (
        <Grommet.Box pad="medium" flex="grow">
            {queryResults.loading && <Grommet.Text>Loading...</Grommet.Text>}
            {queryResults.error && (
                <Grommet.Box gap="small">
                    <Grommet.Text>
                        An error occurred! Please try again later.
                    </Grommet.Text>
                    <Grommet.Box direction="row">
                        <Grommet.Button
                            label="Reload"
                            primary={true}
                            onClick={load}
                        />
                    </Grommet.Box>
                </Grommet.Box>
            )}
            {queryResults.data ? (
                <Grommet.Box>
                    <ConcertHeading concert={queryResults.data.concert} />
                    <ConcertArtists
                        artists={queryResults.data.concert.artists.edges.map(
                            (edge) => {
                                return edge.node;
                            },
                        )}
                    />
                    <ConcertSongs
                        songs={queryResults.data.concert.songs.edges.map(
                            (edge) => {
                                return edge.node;
                            },
                        )}
                        playable={true}
                    />
                    <Grommet.Box
                        direction="row"
                        margin={{ vertical: "small" }}
                        gap="small"
                    >
                        <Grommet.Button label="Back" onClick={history.goBack} />
                        <Grommet.Button
                            label={
                                downloadDisabled
                                    ? "Available Offline"
                                    : "Download"
                            }
                            icon={<Icons.Download />}
                            primary={true}
                            onClick={download}
                            disabled={downloadDisabled}
                        />
                    </Grommet.Box>
                </Grommet.Box>
            ) : null}
        </Grommet.Box>
    );
};

export default Concert;
