import React from "react";
import * as Grommet from "grommet";
import Menu from "./Menu";
import Upload from "./Upload";
import { Route, Switch, Redirect } from "react-router-dom";
import Library from "./Library";
import Concert from "./Concert";
import { AppContext } from "./context";
import Login from "./Login";
import Notifications from "./Notifications";
import NowPlaying from "./NowPlaying";
import Settings from "./Settings";
import Offline from "./Offline";
import Search from "./Search";
import { fetchUrl } from "./helpers";
import data from "./static/oneSecondOfSilence";
import { USER } from "./fragments";
import { useQuery } from "graphql-hooks";

const ME_QUERY = `
    query Me {
        me {
            ${USER}
        }
    }
`;

const reducer = (state, action) => {
    switch (action.type) {
        case "set-selected-track": {
            return {
                ...state,
                selectedTrack: action.payload,
            };
        }
        case "add-to-download-queue": {
            return {
                ...state,
                downloadQueue: [...state.downloadQueue, action.payload],
            };
        }
        case "pop-from-download-queue": {
            const downloadQueue = [...state.downloadQueue];
            downloadQueue.splice(0, 1);
            return {
                ...state,
                downloadQueue,
            };
        }
        case "toggle-notifications": {
            return {
                ...state,
                showNotifications: !state.showNotifications,
            };
        }
        case "set-me": {
            return {
                ...state,
                me: action.payload,
            };
        }
        default: {
            throw new Error("Invalid action type for App reducer.");
        }
    }
};

const initialState = {
    selectedTrack: null,
    audio: new Audio(`data:audio/mp3;base64,${data}`),
    showNotifications: false,
    downloadQueue: [],
    me: null,
};

const App = () => {
    const [appState, appDispatch] = React.useReducer(reducer, initialState);
    const { data } = useQuery(ME_QUERY);

    React.useEffect(() => {
        if (data && data.me) {
            appDispatch({ type: "set-me", payload: data.me });
        }
    }, [data]);

    const selectTrack = React.useCallback(
        async (track) => {
            if (track) {
                try {
                    await appState.audio.play();
                } catch {
                    // pass
                }
                const url = await fetchUrl(track);
                appDispatch({
                    type: "set-selected-track",
                    payload: track,
                });
                if (appState.audio.src) {
                    try {
                        URL.revokeObjectURL(appState.audio.src);
                    } catch {
                        // pass
                    }
                }
                appState.audio.src = url;
                try {
                    await appState.audio.play();
                } catch {
                    appDispatch({
                        type: "set-selected-track",
                        payload: null,
                    });
                    appState.audio.src = null;
                }
            } else {
                try {
                    await appState.audio.pause();
                } catch {
                    // pass
                }
                appDispatch({
                    type: "set-selected-track",
                    payload: null,
                });
            }
        },
        [appState.audio],
    );

    return (
        <AppContext.Provider value={{ appState, appDispatch, selectTrack }}>
            <Grommet.Box flex={true}>
                <Menu />
                <Notifications />
                <Grommet.Box flex={true} overflow="scroll">
                    <Switch>
                        <Route path="/login" exact={true}>
                            <Login />
                        </Route>
                        <Route path="/library" exact={true}>
                            <Library />
                        </Route>
                        <Route path="/search/:search" exact={true}>
                            <Search />
                        </Route>
                        <Route path="/offline" exact={true}>
                            <Offline />
                        </Route>
                        <Route path="/concert/:uuid" exact={true}>
                            <Concert />
                        </Route>
                        <Route path="/upload">
                            <Upload />
                        </Route>
                        <Route path="/settings">
                            <Settings />
                        </Route>
                        <Redirect to="/library" />
                    </Switch>
                </Grommet.Box>
                <NowPlaying />
            </Grommet.Box>
        </AppContext.Provider>
    );
};

export default App;
