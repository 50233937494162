import FileUpload from "./FileUpload";
import React from "react";
import ConcertDetails from "./ConcertDetails";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { cloneDeep, set } from "lodash";
import SongDetails from "./SongDetails";
import UploadSummary from "./UploadSummary";

const reducer = (state, action) => {
    switch (action.type) {
        case "set-files": {
            const songs = action.payload.map((file, index) => {
                return {
                    song: {},
                    trackNumber: index + 1,
                    note: "",
                    file,
                };
            });
            return {
                ...state,
                songs,
            };
        }
        case "on-change": {
            const { name, value } = action.payload;
            const newState = cloneDeep(state);
            set(newState, name, value);
            return newState;
        }
        case "add-artist": {
            return {
                ...state,
                artists: [
                    ...state.artists,
                    {
                        artist: {},
                        main: false,
                        order: state.artists.length + 1,
                    },
                ],
            };
        }
        case "remove-artist": {
            const artists = cloneDeep(state.artists);
            artists.splice(action.payload, 1);
            return {
                ...state,
                artists,
            };
        }
        default: {
            throw new Error("Unknown action for Upload reducer.");
        }
    }
};

const INITIAL_STATE = {
    date: "",
    venue: "",
    artists: [],
    songs: [],
};

const Upload = () => {
    const [state, dispatch] = React.useReducer(reducer, INITIAL_STATE);
    const location = useLocation();
    return (
        <Switch>
            {state.songs.length <= 0 &&
                location.pathname !== "/upload/files" && (
                    <Redirect to="/upload/files" />
                )}
            <Route path="/upload/files">
                <FileUpload state={state} dispatch={dispatch} />
            </Route>
            <Route path="/upload/details">
                <ConcertDetails state={state} dispatch={dispatch} />
            </Route>
            <Route path="/upload/songs/:index">
                <SongDetails state={state} dispatch={dispatch} />
            </Route>
            <Route path="/upload/summary">
                <UploadSummary state={state} />
            </Route>
            <Redirect to="/upload/files" />
        </Switch>
    );
};

export default Upload;
