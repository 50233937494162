import * as Grommet from "grommet";
import * as Icons from "grommet-icons";
import React from "react";
import { retrieveSetting, storeSetting } from "./helpers";
import { useHistory } from "react-router-dom";
import { AppContext } from "./context";

const Settings = () => {
    const history = useHistory();
    const { appState } = React.useContext(AppContext);
    const [useAscii, setUseAscii] = React.useState(retrieveSetting("useAscii"));

    const logout = React.useCallback(() => {
        window.localStorage.removeItem("TOKEN");
        history.push("/login");
    }, [history]);

    React.useEffect(() => {
        storeSetting("useAscii", useAscii);
    }, [useAscii]);

    return (
        <Grommet.Box pad="medium" flex="grow">
            <Grommet.Heading level={2}>Settings</Grommet.Heading>
            <Grommet.CheckBox
                label="Prefer ASCII and title-cased names"
                checked={useAscii}
                onChange={(event) => {
                    setUseAscii(event.target.checked);
                }}
            />
            {appState.me && (
                <Grommet.Box direction="row" margin={{ vertical: "medium" }}>
                    <Grommet.Button
                        label="Logout"
                        icon={<Icons.Logout />}
                        primary={true}
                        onClick={logout}
                    />
                </Grommet.Box>
            )}
        </Grommet.Box>
    );
};

export default Settings;
