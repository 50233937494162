import * as Grommet from "grommet";
import React from "react";
import { retrieveSetting, titlecase } from "./helpers";

const ConcertHeading = ({ concert, size, direction }) => {
    const useAscii = retrieveSetting("useAscii");
    return (
        <Grommet.Box
            direction={direction}
            gap={direction === "row" ? "small" : null}
        >
            <Grommet.Text size={size}>{concert.date}</Grommet.Text>
            <Grommet.Text size={size}>
                {useAscii ? titlecase(concert.venue) : concert.venue}
            </Grommet.Text>
        </Grommet.Box>
    );
};

export default ConcertHeading;
