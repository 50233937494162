import React from "react";
import * as Grommet from "grommet";
import { useHistory } from "react-router-dom";

const FileUpload = ({ state, dispatch }) => {
    const history = useHistory();
    const ref = React.useRef();

    const openFileDialog = React.useCallback(() => {
        if (ref.current && ref.current.click) {
            ref.current.click();
        }
    }, []);

    const wrappedOnFilesAdded = React.useCallback(
        (event) => {
            const files = event.target.files;
            const fileArray = [];
            for (let i = 0; i < files.length; i++) {
                fileArray.push(files.item(i));
            }
            dispatch({ type: "set-files", payload: fileArray });
        },
        [dispatch],
    );

    return (
        <Grommet.Box pad="medium" flex="grow">
            <Grommet.Heading level={2}>File Upload</Grommet.Heading>
            <Grommet.Box
                height="small"
                width="medium"
                border="all"
                background="brand"
                onClick={openFileDialog}
                alignContent="center"
                justify="center"
            >
                <Grommet.Text textAlign="center">
                    Click here to upload files!
                </Grommet.Text>
                <Grommet.TextInput
                    style={{
                        display: "none",
                    }}
                    name="files"
                    ref={ref}
                    type="file"
                    multiple={true}
                    accept="audio/mpeg"
                    onChange={wrappedOnFilesAdded}
                />
            </Grommet.Box>
            {state.songs.length > 0 ? (
                <Grommet.Box gap="small" margin={{ top: "medium" }}>
                    {state.songs.map((song) => {
                        return (
                            <Grommet.Text key={song.file.name}>
                                {song.file.name}
                            </Grommet.Text>
                        );
                    })}
                </Grommet.Box>
            ) : null}
            <Grommet.Box direction="row" gap="small" margin={{ top: "medium" }}>
                <Grommet.Button
                    primary={true}
                    label="Next"
                    onClick={() => {
                        history.push("/upload/details");
                    }}
                    disabled={state.songs.length <= 0}
                />
            </Grommet.Box>
        </Grommet.Box>
    );
};

export default FileUpload;
