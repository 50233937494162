import * as Grommet from "grommet";
import * as Icons from "grommet-icons";
import * as fragments from "./fragments";
import { AppContext } from "./context";
import React from "react";
import {
    trackName,
    retrieveOfflineConcerts,
    secondsToDisplayString,
} from "./helpers";
import { useManualQuery } from "graphql-hooks";

const PREVIOUS_QUERY = `
    query PreviousTrack($uuid: String!) {
        previous(uuid: $uuid) {
            ${fragments.CONCERT_SONG}
        }
    }
`;

const NEXT_QUERY = `
    query NextTrack($uuid: String!) {
        next(uuid: $uuid) {
            ${fragments.CONCERT_SONG}
        }
    }
`;

const NowPlaying = () => {
    const { appState, selectTrack } = React.useContext(AppContext);
    const [expanded, setExpanded] = React.useState(false);
    const [playing, setPlaying] = React.useState(false);
    const [currentTime, setCurrentTime] = React.useState(0.0);
    const [duration, setDuration] = React.useState(null);
    const [ready, setReady] = React.useState(false);

    const [fetchPrevious] = useManualQuery(PREVIOUS_QUERY);
    const [fetchNext] = useManualQuery(NEXT_QUERY);

    const toggleExpanded = React.useCallback(() => {
        setExpanded((prev) => {
            return !prev;
        });
    }, []);

    const seek = React.useCallback(
        (event) => {
            appState.audio.currentTime = event.target.value;
        },
        [appState.audio],
    );

    const playPause = React.useCallback(async () => {
        try {
            if (appState.audio.paused) {
                await appState.audio.play();
            } else {
                await appState.audio.pause();
            }
        } catch {
            selectTrack(null);
        }
    }, [appState.audio, selectTrack]);

    const nextTrack = React.useCallback(
        async (mode) => {
            if (!appState.selectedTrack) {
                return;
            }
            const offlineConcerts = await retrieveOfflineConcerts();
            if (appState.selectedTrack.concertUuid in offlineConcerts) {
                const concert =
                    offlineConcerts[appState.selectedTrack.concertUuid];
                const songs = concert.songs.edges.map((edge) => {
                    return edge.node;
                });
                const newTrackNumber =
                    mode === "previous"
                        ? appState.selectedTrack.trackNumber - 1
                        : appState.selectedTrack.trackNumber + 1;
                selectTrack(songs[newTrackNumber - 1]);
            } else {
                const queryFunction =
                    mode === "previous" ? fetchPrevious : fetchNext;
                const results = await queryFunction({
                    variables: { uuid: appState.selectedTrack.uuid },
                });
                if (results.error) {
                    selectTrack(null);
                } else {
                    selectTrack(results.data[mode]);
                }
            }
        },
        [appState.selectedTrack, fetchNext, fetchPrevious, selectTrack],
    );

    const onTimeUpdate = React.useCallback((event) => {
        setCurrentTime(event.target.currentTime);
    }, []);

    const onDurationChange = React.useCallback((event) => {
        setDuration(event.target.duration);
    }, []);

    const onPlay = React.useCallback(() => {
        setPlaying(true);
    }, []);

    const onPause = React.useCallback(() => {
        setPlaying(false);
    }, []);

    const onEnded = React.useCallback(() => {
        nextTrack("next");
    }, [nextTrack]);

    const onLoadStart = React.useCallback(() => {
        setReady(false);
        setDuration(null);
        setCurrentTime(0.0);
    }, []);

    const onCanPlay = React.useCallback(() => {
        setReady(true);
    }, []);

    React.useEffect(() => {
        appState.audio.addEventListener("timeupdate", onTimeUpdate);
        appState.audio.addEventListener("durationchange", onDurationChange);
        appState.audio.addEventListener("play", onPlay);
        appState.audio.addEventListener("pause", onPause);
        appState.audio.addEventListener("loadstart", onLoadStart);
        appState.audio.addEventListener("canplay", onCanPlay);

        return () => {
            appState.audio.removeEventListener("timeupdate", onTimeUpdate);
            appState.audio.removeEventListener(
                "durationchange",
                onDurationChange,
            );
            appState.audio.removeEventListener("play", onPlay);
            appState.audio.removeEventListener("pause", onPause);
            appState.audio.removeEventListener("loadstart", onLoadStart);
            appState.audio.removeEventListener("canplay", onCanPlay);
        };
    }, [
        appState.audio,
        nextTrack,
        onCanPlay,
        onDurationChange,
        onEnded,
        onLoadStart,
        onPause,
        onPlay,
        onTimeUpdate,
    ]);

    React.useEffect(() => {
        if (!ready) {
            return;
        }
        appState.audio.addEventListener("ended", onEnded);
        return () => {
            appState.audio.removeEventListener("ended", onEnded);
        };
    }, [appState.audio, onEnded, ready]);

    const controlsDisabled = React.useMemo(() => {
        if (playing) {
            return false;
        }
        return !(ready && duration && appState.selectedTrack);
    }, [appState.selectedTrack, duration, playing, ready]);

    return (
        <Grommet.Footer
            responsive={false}
            pad="xsmall"
            background="brand"
            className="footer"
        >
            <Grommet.Box flex="grow" responsive={false}>
                <Grommet.Text
                    textAlign="center"
                    alignSelf="center"
                    onClick={toggleExpanded}
                >
                    {appState.selectedTrack
                        ? trackName(appState.selectedTrack)
                        : "Nothing playing"}
                </Grommet.Text>
                {expanded ? (
                    <Grommet.Box pad={{ top: "xsmall" }} responsive={false}>
                        <Grommet.Box
                            direction="row"
                            pad={{ vertical: "xsmall" }}
                            align="center"
                            justify="evenly"
                            responsive={false}
                        >
                            <Grommet.Button
                                onClick={() => {
                                    nextTrack("previous");
                                }}
                                icon={<Icons.ChapterPrevious size="24px" />}
                                disabled={controlsDisabled}
                                focusIndicator={false}
                            />
                            <Grommet.Button
                                onClick={playPause}
                                icon={
                                    playing ? (
                                        <Icons.Pause size="24px" />
                                    ) : (
                                        <Icons.Play size="24px" />
                                    )
                                }
                                disabled={controlsDisabled}
                                focusIndicator={false}
                            />
                            <Grommet.Button
                                onClick={() => {
                                    nextTrack("next");
                                }}
                                icon={<Icons.ChapterNext size="24px" />}
                                disabled={controlsDisabled}
                                focusIndicator={false}
                            />
                        </Grommet.Box>
                        <Grommet.Box
                            pad={{ horizontal: "small", vertical: "xsmall" }}
                            responsive={false}
                        >
                            <Grommet.RangeInput
                                min={0}
                                max={duration}
                                step={1}
                                value={currentTime}
                                onChange={seek}
                                disabled={controlsDisabled}
                            />
                        </Grommet.Box>
                        <Grommet.Box
                            direction="row"
                            justify="between"
                            pad={{ horizontal: "small" }}
                            responsive={false}
                        >
                            {appState.selectedTrack && duration ? (
                                <>
                                    <Grommet.Text>
                                        {secondsToDisplayString(currentTime)}
                                    </Grommet.Text>
                                    <Grommet.Text>
                                        {secondsToDisplayString(duration)}
                                    </Grommet.Text>
                                </>
                            ) : (
                                <>
                                    <Grommet.Text>
                                        {secondsToDisplayString(0)}
                                    </Grommet.Text>
                                    <Grommet.Text>
                                        {secondsToDisplayString(0)}
                                    </Grommet.Text>
                                </>
                            )}
                        </Grommet.Box>
                    </Grommet.Box>
                ) : null}
            </Grommet.Box>
        </Grommet.Footer>
    );
};

export default NowPlaying;
