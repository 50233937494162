import * as Grommet from "grommet";
import React from "react";
import { retrieveSetting, titlecase } from "./helpers";

const ConcertArtists = ({ artists, size, direction }) => {
    const useAscii = retrieveSetting("useAscii");
    return (
        <Grommet.Box
            direction={direction}
            gap={direction === "row" ? "small" : null}
        >
            {artists.map((obj) => {
                return (
                    <Grommet.Text key={obj.artist.uuid} size={size}>
                        {useAscii
                            ? titlecase(obj.artist.asciiName)
                            : obj.artist.name}
                    </Grommet.Text>
                );
            })}
        </Grommet.Box>
    );
};

export default ConcertArtists;
